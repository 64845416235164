import { PaymentMethod } from "Constants";
import { TranslateFunc } from "LanguageProvider";
import { OnlineCheckoutFormValues } from "../OnlineCheckoutForm";
import { CartProduct, MenuCategoryProducts, FoodOptions, EatingPreference, UserAccount } from "Types";
import { isEatingPreferenceCatering, appendDefaultCountryCodeIfMissing, formatSwedishFinancialNumbers } from "Utils";

export const getInitialValues = (
    formValsCookie: any,
    foodOptions: FoodOptions,
    saveMeAndAcceptsCampaignsCookie: string,
    isThirdPartyDelivery: boolean,
    userAccount: UserAccount | null
): OnlineCheckoutFormValues => {
    let defaultValues = {
        firstName: "",
        lastName: "",
        phoneNumber: "",
        phoneNumber2: "",
        email: "",
        addressLine: "",
        city: "",
        doorCode: "",
        swishNumber: "",
        comment: "",
        saveMeAndAcceptsCampaigns: !!(saveMeAndAcceptsCampaignsCookie === "true" || userAccount?.acceptsMarketing),
        acceptTerms: false,
        floorNumber: "",
        postCode: "",
        tip: 0,
        paymentInformation: {
            paymentMethod: PaymentMethod.SWISH
        },
        invoiceData: {
            organisationNumber: "",
            invoiceAddress: {
                tmpUseSameAsAbove: foodOptions.eatingPreference === EatingPreference.CATERING_DELIVERY,
                name: "",
                addressLine: "",
                postCode: "",
                city: ""
            }
        }
    };

    const isHomeDelivery = foodOptions.eatingPreference === EatingPreference.HOME_DELIVERY;
    const isCateringDelivery = foodOptions.eatingPreference === EatingPreference.CATERING_DELIVERY;
    const isDelivery = isHomeDelivery || isCateringDelivery;

    if (!!userAccount) {
        const { contactInformation } = userAccount;

        // Previously userAccounts could remove their phoneNumber
        // causing the field to be empty. In such case we want to check
        // their cookies if a phoneNumber has been stored. If nothing is found
        // we return a blank string
        const _phoneNumber = contactInformation.phoneNumber
            ? appendDefaultCountryCodeIfMissing(contactInformation.phoneNumber)
            : formValsCookie
              ? appendDefaultCountryCodeIfMissing(formValsCookie.phoneNumber)
              : "";

        // We only concat lastName with name if it's NOT delivery
        const firstName = !isDelivery
            ? `${contactInformation.name} ${contactInformation.lastName}`
            : contactInformation.name;

        defaultValues = {
            ...defaultValues,
            firstName,
            phoneNumber: _phoneNumber,
            swishNumber: _phoneNumber,
            email: contactInformation.email,
            addressLine: isHomeDelivery ? contactInformation.addressLine || "" : ""
        };
    } else if (!!formValsCookie) {
        const _phoneNumber = formValsCookie.phoneNumber
            ? appendDefaultCountryCodeIfMissing(formValsCookie.phoneNumber)
            : "";
        defaultValues = {
            ...defaultValues,
            ...formValsCookie,
            phoneNumber: _phoneNumber,
            swishNumber: _phoneNumber
        };
    }

    if (isDelivery) {
        defaultValues = {
            ...defaultValues,
            lastName: userAccount?.contactInformation?.lastName ? userAccount?.contactInformation?.lastName : ""
        };
    }

    if (isEatingPreferenceCatering(foodOptions.eatingPreference)) {
        defaultValues = {
            ...defaultValues,
            postCode: foodOptions.deliveryInformation!.postCode
        };
    } else if (isHomeDelivery) {
        const { country, fee, latLng, ...rest } = foodOptions.deliveryInformation!;
        defaultValues = {
            ...defaultValues,
            ...rest
        };
        if (isThirdPartyDelivery) {
            defaultValues = {
                ...defaultValues,
                addressLine: foodOptions.deliveryInformation!.street!,
                postCode: foodOptions.deliveryInformation!.postCode
            };
        }
    }

    //@ts-ignore
    return defaultValues;
};

/**NOTE in test suite */
export const getDiscountedProductIdsFromCartProducts = (
    cartProducts: any[],
    discountCategoryAndProductIds: MenuCategoryProducts[]
): string[] => {
    const categoryAndProductIds = discountCategoryAndProductIds.map((cat: MenuCategoryProducts) => ({
        category: cat.categoryIds,
        products: cat.productIds
    }));
    const discountProductIds = cartProducts
        .filter((cart: any) => {
            const menuProductId = cart.menuProduct?.id ? cart.menuProduct?.id : cart.menuBundleProduct?.id;
            const menuCategoryId = cart.orderProduct.menuCategoryId;
            const findCategory =
                categoryAndProductIds?.some((cat: any) => cat.category?.includes(menuCategoryId)) ?? false;
            const findProduct =
                categoryAndProductIds?.some((prod: any) => prod.products?.includes(menuProductId)) ?? false;
            return findCategory || findProduct;
        })
        .map((cart: any) => cart.id);

    return discountProductIds;
};

/** Test suite */
export const getCombinedDiscountedProductIds = (
    cartProducts: CartProduct[],
    discountedProductsIds: string[]
): string[] => {
    const fixedDiscountIsActive = cartProducts.some((cart: CartProduct) => cart?.fixedDiscount?.isFixedDiscountActive);

    if (!fixedDiscountIsActive) {
        return discountedProductsIds;
    }

    return cartProducts
        .filter((cart: CartProduct) => {
            if (discountedProductsIds.includes(cart.id)) {
                const hasFixedDiscount = !!cart?.fixedDiscount
                if(hasFixedDiscount) {
                    return cart.fixedDiscount?.canCombineDiscounts;
                }
                return true;
            }
            return false;
        })
        .map((cart: CartProduct) => cart.id);
};

export const getPaymentButtonPrefixText = (
    isReadyToPay: boolean,
    hasToCheckFields: boolean,
    hasNotMetMinDeliveryAmount: boolean,
    isCatering: boolean,
    minDeliveryAmountLeft: number,
    translate: TranslateFunc
) => {
    if (hasToCheckFields) {
        return translate("checkFields");
    } else if (hasNotMetMinDeliveryAmount) {
        const amountLeftTid = isCatering ? "restForCatering" : "restForHomeDelivery";
        const amountLeft = formatSwedishFinancialNumbers(minDeliveryAmountLeft);
        return `${translate(amountLeftTid)} ${amountLeft}`;
    } else if (isReadyToPay) {
        return translate("pay");
    } else {
        return translate("okay");
    }
};
